<!--
 * @Author: 蒋威
 * @Date: 2022-05-09 17:36:01
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-10 15:28:18
 * @Description: 党建列表
-->
<template>
  <div class="response news-wrap">
    <div
      class="news-box pointer"
      v-for="(item, index) in newsList"
      :key="index"
      @click="toDetail(item)"
      :class="item.img ? 'hasimg' : ''"
    >
      <div class="img-box" v-if="item.img">
        <img :src="item.img" />
      </div>
      <div class="conetnt-box">
        <h3 class="ellipsis-2">
          {{ item.title }}
        </h3>
        <p class="ellipsis-2">
          {{ item.brief }}
        </p>
      </div>
      <div class="time">
        <div class="time-box">
          <h2>{{ $getDate(item.date, 'MD') }}</h2>
          <p>{{ $getDate(item.date, 'Y') }}</p>
        </div>
        <div class="more-box">
          <i class="iconfont icon-jiantou"></i>
        </div>
      </div>
    </div>

    <Page
      :page-size="pageSize"
      :total="total"
      :current-page="currentPage"
      @currentChange="currentChange"
    ></Page>
  </div>
</template>

<script>
import { queryByChannelId } from '@/api/news/index.js'
export default {
  props: {
    id: {
      type: Array | String,
    },
  },
  data() {
    return {
      form: {
        limit: 6,
        page: 1,
      },
      total: 200,
      newsList: [
        {
          title: ' 太阳慈善助学基金会党支部2022年第二次党员大会（20220119）',
          brief:
            '   党员大会：关于召开顺丰集团党委推荐提名广东省出席党的二十代表候选人推荐人选征求意见会',
          year: '2020',
          month: '09-18',
          date: '2020-09-18 16:00:21',
          img: '',
        },
        {
          title: ' 太阳慈善助学基金会党支部2022年第二次党员大会（20220119）',
          brief:
            '   党员大会：关于召开顺丰集团党委推荐提名广东省出席党的二十代表候选人推荐人选征求意见会',
          year: '2020',
          month: '09-18',
          date: '2020-09-18 16:00:21',
          img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        },
        {
          title: ' 太阳慈善助学基金会党支部2022年第二次党员大会（20220119）',
          brief:
            '   党员大会：关于召开顺丰集团党委推荐提名广东省出席党的二十代表候选人推荐人选征求意见会',
          year: '2020',
          month: '09-18',
          date: '2020-09-18 16:00:21',
          img: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
        },
        {
          title: ' 太阳慈善助学基金会党支部2022年第二次党员大会（20220119）',
          brief:
            '   党员大会：关于召开顺丰集团党委推荐提名广东省出席党的二十代表候选人推荐人选征求意见会',
          year: '2020',
          month: '09-18',
          date: '2020-09-18 16:00:21',
          img: '',
        },
        {
          title: ' 太阳慈善助学基金会党支部2022年第二次党员大会（20220119）',
          brief:
            '   党员大会：关于召开顺丰集团党委推荐提名广东省出席党的二十代表候选人推荐人选征求意见会',
          year: '2020',
          month: '09-18',
          date: '2020-09-18 16:00:21',
          img: '',
        },
        {
          title: ' 太阳慈善助学基金会党支部2022年第二次党员大会（20220119）',
          brief:
            '   党员大会：关于召开顺丰集团党委推荐提名广东省出席党的二十代表候选人推荐人选征求意见会',
          year: '2020',
          month: '09-18',
          date: '2020-09-18 16:00:21',
          img: '',
        },
      ],
    }
  },
  methods: {
    async queryByChannelId(param) {
      let res = await queryByChannelId({ id: this.id, ...this.form })
      console.log(res, 'ssdtgvss')

      if (res.code === this.$httpCode) {
        this.newsList = res.data.newsPage.data
        this.total = res.data.newsPage.total
        this.form.page = res.data.newsPage.current_page
      } else {
        this.newsList = []
      }
    },
    currentChange(val) {
      this.form.page = val
      this.queryByChannelId()
    },
    // 重置数据
    reset() {
      this.newsList = []
      this.total = 0
      this.form = {
        limit: 6,
        page: 1,
      }
    },
    // 初始化
    init() {
      this.reset()
      this.queryByChannelId()
    },

    toDetail(item) {
      const moduleId = this.$route.query.moduleId
      let query = {}
      if (moduleId) {
        query = { id: item.id, moduleId: moduleId }
      } else {
        query = { id: item.id }
      }
      const routeData = this.$router.resolve({
        name: 'newsInfo',
        query: query,
      })
      window.open(routeData.href, '_blank')
    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.news-wrap {
  padding: 90px 0 70px 0;
  .news-box {
    display: flex;
    height: 138px;
    margin-bottom: 30px;
    .img-box {
      width: 268px;
      height: 207px;
      margin-right: 6px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .conetnt-box {
      flex: 1;
      margin: 22px 0;
      padding: 0 20px;
      h3 {
        height: 48px;
        padding-right: 100px;
        font-size: 20px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #242121;
        line-height: 24px;
      }
      p {
        margin-top: 6px;
        height: 40px;
        padding-right: 100px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #5b5a5a;
        line-height: 20px;
      }
    }
    .time {
      flex: 0 0 210px;
      width: 210px;
      margin: 26px 0;
      border-left: 1px solid #cecfce;
      .time-box {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
          padding-left: 70px;
          height: 26px;
          font-size: 20px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #5b5a5a;
          line-height: 24px;
          margin-bottom: 6px;
        }
        p {
          padding-left: 70px;
          height: 20px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #5b5a5a;
          line-height: 20px;
        }
      }
      .more-box {
        display: none;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        i {
          width: 40px;
          height: 40px;
          font-size: 40px;
          line-height: 40px;
          color: #fff;
          &:hover {
            transform: scale(1.1);
            transition: all 0.5s;
          }
        }
      }
    }
    &.hasimg {
      height: 207px;
    }
    &:hover {
      background-color: #e3a63b;
      .conetnt-box {
        h3 {
          color: #ffffff;
        }
        p {
          color: #ffffff;
        }
      }
      .time {
        .time-box {
          display: none;
        }
        .more-box {
          display: flex;
          i {
          }
        }
      }
    }
  }
}
</style>