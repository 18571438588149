<!--
 * @Author: 蒋威
 * @Date: 2022-05-07 13:39:02
 * @LastEditors: 蒋威
 * @LastEditTime: 2022-05-16 16:45:10
 * @Description: 党建动态
-->

<template>
  <div class="homepage-wrap">
    <NavBox @changeMenu="changeMenu" :menuList="menuList" ref="NavBoxRef" />
    <Banner :bannerList="bannerList"></Banner>

    <template>
      <PartyList :id="columnId"></PartyList>
    </template>
  </div>
</template>

<script>
import PartyList from './components/PartyList.vue'
import { channelById } from '@/api/menu/index.js'
import { websiteAds_list } from '@/api/homePage/index.js'
import { queryByChannelId } from '@/api/news/index.js'
export default {
  name: 'partyBuilding',
  components: { PartyList },
  data() {
    return {
      // 二级菜单数据
      menuList: [
        // { name: '全部活动', id: 7, type: 'all' },
      ],
      // 选中的二级菜单
      activeItem: {},
      // 轮播图
      bannerList: [],
      menuType: null,
      // 栏目id
      columnId: null,
      model: null,
    }
  },
  methods: {
    getmenuType() {
      const id = this.$route.query.id
      if (id) {
        this.menuList.map((item) => {
          if (item.id - 0 == id - 0) {
            this.columnId = item.id
            this.activeItem = item
            this.menuType = item.type
            this.model = item.model
          }
        })
      } else {
        this.columnId = this.menuList[0].id
        this.activeItem = this.menuList[0]
        this.menuType = this.menuList[0].type
        this.model = this.menuList[0].model
      }
    },
    changeMenu(item) {
      this.menuType = item.type
      const moduleId = this.$route.query.moduleId
      let query = {}
      if (moduleId) {
        query = { id: item.id, moduleId: moduleId }
      } else {
        query = { id: item.id }
      }
      this.reload()
      this.$router.push({
        name: this.$route.name,
        query: query,
      })
    },
    // 获取轮播
    async getBannerList() {
      const res = await websiteAds_list({
        banner_type: 2,
        page: 1,
        limit: 1,
        column_id: this.$route.query.moduleId,
      })
      if (res.code === this.$httpCode) {
        this.bannerList = res.data.data
      }
    },

    async init() {
      this.getBannerList()
      const res = await channelById({ diff: 'web', id: this.$route.query.moduleId })
      if (res.code === this.$httpCode) {
        this.menuList = res.data.websiteChannelVoList || []
        if (this.menuList.length < 1) {
          this.columnId = res.data.WebsiteChannel.id
          this.menuType = res.data.WebsiteChannel.type
          this.model = res.data.WebsiteChannel.model
          this.activeItem = { id: res.data.WebsiteChannel.id }
        } else {
          this.getmenuType()
        }
        // console.log(this.menuType, ' this.menuType')
      }
    },
  },
  created() {
    this.init()
  },
}
</script>

<style lang="scss" scoped>
.homepage-wrap {
  width: 100%;
}
</style>
